import { useState, useEffect } from "react";
import "./FaqChild.scss";

import { useLocation, useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import downArrow from "../../Assets/SideBar-Image/drop-down-black.png";
import del from "../../Assets/SideBar-Image/del.png";
import FaqChildHeader from "../../Component/Header/ChildHeader/FaqChildHeader";
import { ConfirmDelete } from "../../Utills/ConfirmAlerts";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import Loader from "../../Utills/Loader/Loader";
import NoData from "../../Utills/NoData/NoData";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import RichTag from "../../Utills/RichTag/RichTage";
import { isRichTextContextEmpty } from "../../Utills/utils";

function FAQChild() {
  const axiosInstance = useApiService();
  const [allQuestions, setAllQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { id } = useParams();
  const [updatedQuestion, setUpdatedQuestion] = useState({
    id: "",
    paragraph: "",
  });

  const getAllQuestions = async () => {
    setLoading(true);
    await axiosInstance
      .get(`admin/questions?sectionId=${id}`)
      .then((response) => {
        setAllQuestions(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const updateField = (e: any, id: any) => {
    setUpdatedQuestion({
      ...updatedQuestion,
      id,
      paragraph: e,
    });
  };

  const updateDetails = () => {
    if (isRichTextContextEmpty(updatedQuestion.paragraph)) {
      ToastFailure("Please enter the paragraph ");
      return;
    }

    setLoading(true);
    axiosInstance
      .put(`admin/question`, {
        _id: updatedQuestion.id,
        paragraph: updatedQuestion.paragraph,
      })
      .then((response) => {
        setLoading(false);
        getAllQuestions();
        ToastSuccess(response.data.message);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const deleteFaqChild = async (_id: any) => {
    setLoading(true);
    await axiosInstance
      .delete(`admin/question?questionId=${_id}&sectionId=${id}`)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          ToastSuccess("Successfully Deleted.");
          getAllQuestions();
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getAllQuestions();
  }, [id]);

  return (
    <>
      <FaqChildHeader
        name={`${location.state.name}`}
        id={id}
        getAllQuestions={getAllQuestions}
      />

      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="FaqChild_container MainClass">
          <div className="faq-child TopCommonChild">
            <Accordion defaultActiveKey="0" flush>
              {allQuestions?.length > 0 ? (
                allQuestions.map((item: any, index: number) => {
                  const { question } = item;
                  return (
                    <Accordion.Item
                      className="Accordian-Faq-field"
                      key={item._id}
                      eventKey={item._id}
                    >
                      <Accordion.Header
                        className="HeadingPath"
                        onClick={() =>
                          setUpdatedQuestion({
                            ...updatedQuestion,
                            paragraph: item.paragraph,
                          })
                        }
                      >
                        <h2
                          className="accordion-header-FaqChilds "
                          id="headingTwo"
                        >
                          <img src={downArrow} alt="" className="downArrow" />
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Paragraph {index + 1}
                          </button>
                          <div className="del-buttons">
                            <button className="delete-btn">
                              <a
                                className="Delete-faq FAQDeletAlign"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  ConfirmDelete(item._id, deleteFaqChild);
                                }}
                              >
                                <img
                                  src={del}
                                  className="fa-solid fa-trash-can"
                                />
                                <span className="delete-team-member">
                                  DELETE
                                </span>
                              </a>
                            </button>
                          </div>
                        </h2>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="accordion-body-FaqChild">
                          <div className="questions">
                            <div className="questions-faq">
                              <div className="hed-quest">
                                <div className="update-buttons">
                                  <button className="update-btn">
                                    <a className="update-faq">
                                      <span
                                        className="update-question"
                                        onClick={() => updateDetails()}
                                      >
                                        UPDATE
                                      </span>
                                    </a>
                                  </button>
                                </div>
                              </div>
                              <div className="answer-faq">
                                <RichTag
                                  editerDetails={updatedQuestion.paragraph}
                                  setEditerDetails={(value: any) => {
                                    updateField(value, item._id);
                                  }}
                                  allowImages={false}
                                  allowListFormat={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })
              ) : (
                <NoData content="No FAQ found." />
              )}
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
}
export default FAQChild;
