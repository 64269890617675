import "../Faq.style.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { ToastFailure, ToastSuccess } from "../../../Share/toast/ToastMsg";
import useApiService from "../../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import RichTag from "../../../Utills/RichTag/RichTage";
import { isRichTextContextEmpty } from "../../../Utills/utils";

function FaqChildModal(props: any) {
  const axiosInstance = useApiService();
  const [childModalFaq, setChildModalFaq] = useState({
    paragraph: "",
    sectionId: props.id,
  });

  const addFaqchildModal = () => {
    if (isRichTextContextEmpty(childModalFaq.paragraph)) {
      ToastFailure("Please enter Paragraph");
      return;
    }
    axiosInstance
      .post(`admin/question`, {
        paragraph: childModalFaq.paragraph,
        sectionId: childModalFaq.sectionId,
      })
      .then((response) => {
        props.onHide();
        ToastSuccess(response.data.message);
        props.getAllQuestions();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setChildModalFaq({
      paragraph: "",
      sectionId: props.id,
    });
  }, [props.onHide]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Paragraph
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="faqpopup-contant">
          <div className="faqPopup-container">
            <RichTag
              editerDetails={childModalFaq.paragraph}
              setEditerDetails={(value: any) => {
                setChildModalFaq({
                  ...childModalFaq,
                  paragraph: value,
                });
              }}
              allowImages={false}
              allowListFormat={false}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="faq-cancel-btn">
          CANCEL
        </Button>
        <Button onClick={() => addFaqchildModal()} className="Faq-save-btn">
          CREATE
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FaqChildModal;
