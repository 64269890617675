import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";
import FaqChildModal from "../../../Pages/FAQ/FaqMobal/FaqChildModal";

import FaqMobal from "../../../Pages/FAQ/FaqMobal/FaqMobal";
import { ToastSuccess } from "../../../Share/toast/ToastMsg";
import useApiService from "../../../Utills/ApiAxiosHandler/ApiAxiosHandler";

const FaqChildHeader: React.FC<any> = ({ name, id, getAllQuestions }) => {
  const [modalShow, setModalShow] = useState(false);
  const [sectionModalShow, setSectionModalShow] = useState(false);
  const [sectionName, setSectionName] = useState(name);
  const axiosInstance = useApiService();

  const saveSectionModalHandler = (e: any) => {
    setSectionModalShow(e.showModalFlag);

    if (e.name) {
      axiosInstance
        .put(`admin/section`, {
          name: e.name,
          _id: id,
        })
        .then((response) => {
          setSectionName(e.name);
          ToastSuccess(response.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <div className="header">
        <nav className="navbar-child-venues">
          <div className="content-Child-Headers">
            <div className="headding-child-Headers">
              <div className="backimage">
                <Link to="/faq">
                  <img src={backimage} alt="" className="back-arrow" />
                </Link>
              </div>
              <div
                className="child-Header-content"
                onClick={() => {
                  setSectionModalShow(true);
                }}
              >
                <a className="navbarBrand-childeheaders">
                  FAQ - {sectionName.toUpperCase()}
                </a>
              </div>
            </div>
          </div>
          <div className="button-CHildvanu d-flex">
            <div className="addnewquestionbtn">
              <div className="addNewquestion">
                <Button
                  variant="primary"
                  className="FaqPopupbtn"
                  onClick={() => setModalShow(true)}
                >
                  ADD NEW PARAGRAPH
                </Button>

                <FaqChildModal
                  id={id}
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  getAllQuestions={getAllQuestions}
                />

                {sectionModalShow && (
                  <FaqMobal
                    modalShow={sectionModalShow}
                    setModalShow={saveSectionModalHandler}
                    sectionName={sectionName}
                    type="edit"
                  />
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default FaqChildHeader;
